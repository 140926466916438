import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { connect } from 'react-redux'

import Loader from '../../components/Loader'

import { ROUTE } from '../../config'
import Icon from '../../components/Icon'
import { generatePublicationLink, isWarningLightsPublication } from '../../utils/publication'
import { getRootTopicForWarningLightsPublication } from '../../utils/topics'

import '../../styles/TopicsList.scss'
import './InfinitiTopicsList.scss'

export const getLabelTitle = (topic) => {
  if (topic.video) {
    return 'Video'
  }

  if (topic.bodyPdf) {
    return 'PDF'
  }

  if (topic.bodyHtml || topic.type === 'topic') {
    return 'Topic'
  }

  return ''
}

const TopicsList = ({
  topics,
  publications,
  emptyText = 'No topics found',
  baseRoute,
  className,
  onClick,
  allTopics,
  contentIndex,
  isRoot,
}) => {
  const topicElements = useMemo(() => {
    const publicationsList = publications ? publications.toJS() : []
    let topicsList = topics

    let topicElements = []
    topicsList.forEach((topic, key) => {
      const isFolder = topic.get('type') === 'folder'
      const isPublication = topic.get('type') === 'publication'
      const resourceKey = topic.get('resourceKey')

      const isPdfPublication = publicationsList.some(
        (publication) => publication.resourceKey === resourceKey && !!publication.bodyPdf,
      )

      let link = `${baseRoute}${generatePublicationLink({ isPdfPublication, isFolder, resourceKey })}`

      if (isPublication) {
        const pubInfo = contentIndex[topic.get('id')]
        const isWarningLightsPub = isWarningLightsPublication(pubInfo)
        if (isWarningLightsPub) {
          const warnLightsRootTopic = getRootTopicForWarningLightsPublication(allTopics,publications)
          link = `${baseRoute}${ROUTE.TOPIC}/${warnLightsRootTopic.get('resourceKey')}`
        }
      }

      const labelTitle = getLabelTitle(
        isRoot ? publicationsList.find((p) => p.resourceKey === topic.get('resourceKey')) : topic.toJS(),
      )

      const shouldShowLabelIcon = !isFolder && labelTitle

      topicElements.push(
        <li key={key + 1}>
          <Link
            className={classNames('item-inner', shouldShowLabelIcon && 'with-label')}
            to={link}
            onClick={() => {
              onClick && onClick(topic)
            }}
          >
            <div className='item-iconbar'>
              {shouldShowLabelIcon && <Icon type={`${labelTitle.toLowerCase()}-infiniti`} />}
              <span className='label-title'>{labelTitle}</span>
            </div>
            <div className='item-title'>
              {topic.get('title')} <Icon type='arrow-left' />
            </div>
          </Link>
        </li>,
      )
      return true
    })

    return topicElements
  }, [topics, publications])

  if (topics === null || topics.size === 0) {
    return (
      <Loader type='status' className='inverse'>
        {emptyText}
      </Loader>
    )
  }

  return <ul className={classNames('TopicsList InfinitiTopicsList', className)}>{topicElements}</ul>
}

TopicsList.propTypes = {
  topics: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
  publications: ImmutablePropTypes.list,
  onBookmarkClick: PropTypes.func,
  referrer: PropTypes.oneOf([ROUTE.BROWSE, ROUTE.FAVORITES, ROUTE.TOPPICKS, ROUTE.EXPLORE, ROUTE.SEARCH]),
  emptyText: PropTypes.string,
  baseRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  allTopics: ImmutablePropTypes.list,
  contentIndex: PropTypes.shape({
    publicationType: PropTypes.string,
  }),
  isRoot: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  publications: state.contents.publications,
  allTopics: state.contents.topics,
  contentIndex: state.contents.contentIndex,
})

export default connect(mapStateToProps)(TopicsList)
