import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './RoundBtn.scss'

const RoundBtn = ({ children, className, name = 'round button', ...rest }) => (
  <button type='button' name={name} className={classNames('RoundBtn', className)} {...rest}>
    <span className='icon'>{children}</span>
  </button>
)

RoundBtn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
}

export default RoundBtn
