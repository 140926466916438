import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

//TODO: consider switching to https://react-spring.io/ or another alternative, since react-motion is no longer maintained
import { Motion, spring } from 'react-motion'

export const ScrollSink = ({ scrollTop, scrollLeft, children }) => {
  const scrollRef = useRef(null)

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = scrollTop
      scrollRef.current.scrollLeft = scrollLeft
    }
  }, [scrollTop, scrollLeft])

  return (
    <div className='ScrollSink' ref={scrollRef} style={{ display: 'flex', flex: 1 }}>
      {children}
    </div>
  )
}

ScrollSink.propTypes = {
  scrollTop: PropTypes.number,
  scrollLeft: PropTypes.number,
  children: PropTypes.any.isRequired,
}

/**
 * Component for scrollTop animation
 * (just cover your scrollContainer container with it and change scrollTop prop of MotionScroll)
 *
 * @param {Object} props of component
 * @returns {XML} JSX component
 * @constructor
 */
const MotionScroll = props => {
  const motionStyle = {
    ...(typeof props.scrollTop !== 'undefined'
      ? { scrollTop: props.animate ? spring(props.scrollTop) : props.scrollTop }
      : {}),
    ...(typeof props.scrollLeft !== 'undefined'
      ? { scrollLeft: props.animate ? spring(props.scrollLeft) : props.scrollLeft }
      : {}),
  }
  return (
    <Motion defaultStyle={{ scrollLeft: 0, scrollTop: 0 }} style={motionStyle} onRest={props.onRest}>
      {style => {
        return (
          <ScrollSink {...props} scrollTop={style.scrollTop} scrollLeft={style.scrollLeft}>
            {props.children}
          </ScrollSink>
        )
      }}
    </Motion>
  )
}

MotionScroll.propTypes = {
  scrollTop: PropTypes.number,
  scrollLeft: PropTypes.number,
  children: PropTypes.any.isRequired,
  animate: PropTypes.bool,
  onRest: PropTypes.func,
}

MotionScroll.defaultProps = {
  animate: true,
}

export default MotionScroll
