import React from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import { isNil } from 'lodash'

import VideoPlayer from '../components/VideoPlayer'
import VideoMarkers from '../components/VideoMarkers'

function isYouTubeUrl(url) {
  const regExp = /^https?:\/\/(w{3}.)?youtu?.be/gm
  return !!(url && url.match(regExp))
}

// https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
function getYoutubeVideoId(url) {
  //eslint-disable-next-line
  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  var match = url.match(regExp)
  return match && match[1].length == 11 ? match[1] : false
}

const TopicVideo = ({ video, time, onVideoMarkerClick, onPlay }) => {
  const videoProps = {
    poster: video.thumbnailLink,
    autoplay: !!time, // enable autoplay when there is a time offset for video playback
    controls: true,
    playsinline: true,
    sources: [
      {
        src: video.externalLink,
        type: 'video/mp4',
      },
    ],
    time: time,
  }

  const videoId = getYoutubeVideoId(video.externalLink)

  return (
    <div className='video-container'>
      <div className='video-player-container'>
        {isYouTubeUrl(video.externalLink) ? (
          <YouTube
            key={`${videoId}:${time}`}
            opts={{
              playerVars: {
                rel: 0,
                start: time || 1,
                autoplay: isNil(time) ? 0 : 1
              }
            }}
            className='youtubeContainer'
            videoId={videoId}
          />
        ) : (
          <VideoPlayer {...videoProps} onPlay={onPlay} />
        )}
      </div>
      {video.markers && (
        <VideoMarkers items={video.markers} onClick={(t) => onVideoMarkerClick && onVideoMarkerClick(t)} />
      )}
    </div>
  )
}

TopicVideo.propTypes = {
  video: PropTypes.object.isRequired,
  time: PropTypes.number,
  onVideoMarkerClick: PropTypes.func,
  onPlay: PropTypes.func,
}

export default TopicVideo
