import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { convertDashesToCamelCase } from 'tw-oi-core/utils/ui'

import * as IconSet from '../svg'
import { identity } from 'lodash'

const Icon = ({ type = '', onClick = identity, className, stroke }) => {
  const icon = useMemo(() => {
    const iconType = convertDashesToCamelCase(type)

    if (!iconType || !IconSet[iconType]) {
      return null
    }

    return IconSet[iconType]
  }, [type])

  return (
    <div onClick={onClick} className={classNames('icon', `icon-${type}`, className)}>
      {icon({ stroke })}
    </div>
  )
}

Icon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  stroke: PropTypes.string,
}

export default Icon
