import React from 'react'
import { Link } from 'react-router-dom'
import { identity } from 'lodash/fp'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Highlight from 'react-highlighter'

import Icon from '../Icon'
import Loader from '../Loader'
import './InfinitiTopicsListItem.scss'
import { getLabelTitle } from '../InfinitiTopicsList/InfinitiTopicsList'

const InfinitiTopicListItem = ({ to, onClick = identity, topic, matches, isFetching, searchQuery }) => {
  const subtitle =
    matches && matches.length ? `${matches.length > 1 ? `${matches.length} Matches` : `${matches.length} Match`}` : ''

  const labelTitle = getLabelTitle(topic)

  return (
    <li className='TopicsList__item InfinitiTopicsList__item'>
      <Link className='item-inner' to={to} onClick={() => onClick(topic)}>
        <div className='item-title'>
          <div className='TopicsList__match'>
            <div className='TopicsList__match-subtitle-wrapper'>
              {labelTitle && (
                <span className='TopicsList__match-subtitle-type'>
                  <Icon type={`${labelTitle.toLowerCase()}-infiniti`} /> {labelTitle}
                </span>
              )}

              {isFetching && <Loader className={classNames('inverse', 'Loader--match')} />}
              {!isFetching && !!subtitle && (
                <span className='TopicsList__match-subtitle'>{labelTitle ? `, ${subtitle}` : subtitle}</span>
              )}
            </div>

            {searchQuery ? (
              <Highlight className='TopicsList__match-title highlighted' matchElement='mark' search={searchQuery}>
                {topic.title}
              </Highlight>
            ) : (
              <div className='TopicsList__match-title'>
                {topic.title} <Icon type='arrow-left' />
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  )
}

InfinitiTopicListItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  topic: PropTypes.object,
  matches: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  searchQuery: PropTypes.string,
}

export default InfinitiTopicListItem
