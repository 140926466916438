import React from 'react'
import videojs from 'video.js'
import { checkGlobals } from 'tw-oi-core/utils/ui'
import PropTypes from 'prop-types'

export default class VideoPlayer extends React.Component {

  static propTypes = {
    poster: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
    controls: PropTypes.bool,
    playsinline: PropTypes.bool,
    sources: PropTypes.array,
    time: PropTypes.number,
    onPlay: PropTypes.func
  }

  /**
   * Instantiate Video.js
   * as per http://docs.videojs.com/tutorial-react.html
   *
   * @returns {void}
   */
  componentDidMount() {
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      // TODO: console.log('onPlayerReady', this)
    })

    if (checkGlobals(['cordova','screen'])) {
      this.player.on('fullscreenchange', () => {
        if (this.player.isFullscreen()) {
          window.screen.orientation.lock('landscape')
        } else {
          window.screen.orientation.lock('portrait')
        }
      })
    }

    if (this.props.onPlay) {
      this.player.on('play', this.props.onPlay)
    }
  }

  /**
   * Detects whether there is a time offset available, and start playback if it is
   *
   */
  componentDidUpdate() {
    const { time } = this.props

    if (time !== null) {
      this.player.currentTime(time)
      this.player.play()
    }
  }

  /**
   * Destroy player on unmount
   *
   * @returns {void}
   */
  componentWillUnmount() {
    if (this.player) {
      // setTimeout here is a temporary workaround to DOM update failure
      // Uncaught TypeError: Cannot read property 'replaceChild' of null
      // at Function.replaceChildWithTree (bundle.js:44973)
      // at Object.dangerouslyReplaceNodeWithMarkup
      // TODO: should not occur in React 16.x+
      setTimeout(() => {
        this.player.dispose()
      }, 1)
    }
  }

  /**
   * Wrap the player in a div with a `data-vjs-player` attribute
   * so videojs won't create additional wrapper in the DOM
   * see https://github.com/videojs/video.js/pull/3856
   *
   * @returns {React.Component} <video> + wrapper
   */
  render() {
    return (
      <div data-vjs-player>
        <video ref={ node => this.videoNode = node } className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered" />
      </div>
    )
  }
}
