import React, { useState, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { sortItemsByTitle, filterTopicsByField } from 'tw-oi-core/utils/data'
import { trackExploreSceneClick, trackExploreTopicClick, trackPublicationClick } from 'tw-oi-core/services/analytics'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'

import { ROUTE, BRAND_INFINITI } from '../../config'
import VisualSearch from '../../components/VisualSearch'
import TopicsList from '../../components/TopicsList'
import Loader from '../../components/Loader'
import ScreenContainer from '../../components/ScreenContainer'
import Media from '../../components/Media'
import SearchInput from '../../components/SearchInput'
import { splitArrayIntoChunks } from './utils'
import Icon from '../../components/Icon'
import InfinitiTopicsList from '../../components/InfinitiTopicsList'

import './Explore.scss'

const Explore = ({
  imageMaps,
  imageMapBlobs,
  fetchingImageMaps,
  topics,
  match,
  userMedia,
  history,
  baseRoute,
  publications,
  contents,
  ContentsActions,
  currentBrand,
}) => {
  const [exploreTopicsByChunks, setExploreTopicsByChunks] = useState(null)
  const [exploreTopicsIndex, setExploreTopicsIndex] = useState(0)
  const CHUNK_SIZE = userMedia.isDesktop ? 6 : 5

  useEffect(() => {
    ContentsActions.setSearchQuery('')
  }, [])

  const navigateToImageMap = (imageMap, hotSpotIndex, replace = false) => {
    const url = `${baseRoute}${ROUTE.EXPLORE}/${imageMap}/${hotSpotIndex}`

    // Track analytics event
    const { activeImageMap } = getExploreParams()
    if (activeImageMap !== imageMap) {
      trackExploreSceneClick(imageMap)
    }

    setExploreTopicsIndex(0)

    if (replace) {
      history.replace(url)
    } else {
      history.push(url)
    }
  }

  /**
   * Extracts the parameters from the props (routing)
   *
   * @returns {{activeImageMap: string, activeHotSpotIndex: number}}
   */
  const getExploreParams = () => {
    const { imageMapType: activeImageMap, hotSpotIndex } = match.params
    const activeHotSpotIndex = Number.parseInt(hotSpotIndex, 10)
    return { activeImageMap, activeHotSpotIndex }
  }

  const onTopicClick = (topic) => {
    trackExploreTopicClick(topic.get('title'))
  }

  // prepare data for ImageMap
  const { activeImageMap, activeHotSpotIndex } = getExploreParams()

  const { currentItem, imageMapsItems } = useMemo(() => {
    return {
      currentItem: imageMaps ? imageMaps.find((item) => item.title === activeImageMap) : null,
      // TODO: skip images preload and use direct image map URL?
      imageMapsItems:
        imageMaps && imageMapBlobs
          ? imageMaps.map((imageMap) => ({ ...imageMap, url: imageMapBlobs[imageMap.image.url] }))
          : null,
    }
  }, [imageMaps, imageMapBlobs, activeHotSpotIndex, activeImageMap])

  // prepare data for topics list
  useEffect(() => {
    if (currentItem) {
      const targets = currentItem.hotspots[activeHotSpotIndex].targets
      const targetTopics = sortItemsByTitle(filterTopicsByField(topics, targets, 'resourceKey'))

      setExploreTopicsByChunks(splitArrayIntoChunks(targetTopics.toJS(), CHUNK_SIZE))
    }
  }, [currentItem, activeHotSpotIndex])

  // loading is in progress
  if (fetchingImageMaps) {
    return (
      <div className='Explore'>
        <Loader className='inverse' />
      </div>
    )
  }

  const BrandedTopicList = currentBrand === BRAND_INFINITI ? InfinitiTopicsList : TopicsList

  return (
    <div className='Explore Topics one'>
      <div className='screen-content'>
        <ScreenContainer currentUrl={match.url}>
          <SearchInput
            searchQuery=''
            onChange={ContentsActions.setSearchQuery}
            autoFocus={false}
            onFocus={() => history.push(`${baseRoute}${ROUTE.SEARCH}`)}
          />

          {exploreTopicsByChunks && imageMapsItems && (
            <div className='visual-search-wrapper'>
              <VisualSearch
                imageMaps={imageMapsItems}
                activeImageMap={activeImageMap}
                activeHotSpotIndex={activeHotSpotIndex}
                onChange={navigateToImageMap}
                isDesktop={userMedia.isDesktop}
              />
              <div className='topics'>
                <Media type='desktop'>
                  <h2>Topics to Explore</h2>
                </Media>
                <BrandedTopicList
                  topics={fromJS(exploreTopicsByChunks[exploreTopicsIndex])}
                  baseRoute={match.url}
                  onClick={(topic) => onTopicClick(topic)}
                />

                {exploreTopicsByChunks.length > 1 && (
                  <div className='topics-controls'>
                    <Icon
                      className={classNames('topics-controls__prev', exploreTopicsIndex > 0 && 'active')}
                      onClick={() => exploreTopicsIndex > 0 && setExploreTopicsIndex(exploreTopicsIndex - 1)}
                      type='arrow-left'
                    />

                    <Icon
                      className={classNames(
                        'topics-controls__next',
                        exploreTopicsIndex < exploreTopicsByChunks.length - 1 && 'active',
                      )}
                      onClick={() =>
                        exploreTopicsIndex < exploreTopicsByChunks.length - 1 &&
                        setExploreTopicsIndex(exploreTopicsIndex + 1)
                      }
                      type='arrow-left'
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className='manuals'>
            <h2>Manuals</h2>

            {contents.fetching ? (
              <Loader className='inverse' />
            ) : (
              <BrandedTopicList
                isDesktop={userMedia.isDesktop}
                topics={publications}
                baseRoute={baseRoute + ROUTE.BROWSE}
                publications={publications}
                onClick={(topic) => trackPublicationClick(topic.get('title'))}
              />
            )}
          </div>
        </ScreenContainer>
      </div>
    </div>
  )
}

Explore.propTypes = {
  imageMaps: PropTypes.array,
  imageMapBlobs: PropTypes.object,
  fetchingImageMaps: PropTypes.bool.isRequired,
  topics: ImmutablePropTypes.list,
  match: PropTypes.shape({
    params: PropTypes.shape({
      imageMapType: PropTypes.string,
      hotSpotIndex: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
  userMedia: PropTypes.shape({
    isDesktop: PropTypes.bool,
    isLandscape: PropTypes.bool,
  }).isRequired,
  history: PropTypes.object.isRequired,
  baseRoute: PropTypes.string.isRequired,
  publications: ImmutablePropTypes.list,
  contents: PropTypes.shape({
    searchQuery: PropTypes.string,
    fetching: PropTypes.bool,
  }),
  ContentsActions: PropTypes.shape({
    setSearchQuery: PropTypes.func.isRequired,
  }),
  currentBrand: PropTypes.string,
}

function mapStateToProps({ contents, user, vehicle }) {
  const { topics, imageMaps, imageMapBlobs, fetchingImageMaps, publications } = contents
  const { media: userMedia } = user

  return {
    contents,
    topics,
    imageMaps,
    imageMapBlobs,
    fetchingImageMaps,
    userMedia,
    publications,
    currentBrand: vehicle.currentBrand,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Explore)
