import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { trackSearchResultsTopicClick } from 'tw-oi-core/services/analytics'
import Loader from '../components/Loader'
import TopicLayout from '../components/TopicLayout'
import Icon from '../components/Icon'
import Media from '../components/Media'
import TopicListItem from '../components/TopicListItem'
import InfinitiTopicListItem from '../components/InfinitiTopicsListItem'
import { generatePublicationLink } from '../utils/publication'
import { ROUTE, BRAND_INFINITI } from '../config'

import './PdfPublicationMatches/PdfMatches.scss'

const PublicationGroup = ({
  baseRoute,
  isFetching,
  history,
  searchTopics,
  match,
  contentIndex,
  searchQuery,
  currentBrand,
}) => {
  const {
    params: { publicationId },
  } = match

  const topicGroupItems = useMemo(() => {
    const topicsList = (searchTopics && searchTopics.toJS()) || []

    // redirect to search
    if (!topicsList.length && !searchQuery) {
      history.push(`${baseRoute}${ROUTE.SEARCH}`)
    }

    return topicsList.filter(({ publication }) => {
      return publication.publicationId === publicationId
    }, topicsList)
  }, [searchTopics])

  return (
    <TopicLayout backRoute={() => history.goBack()} backTitle='Search Results'>
      <Media type='desktop'>
        <ul className='topics-breadcrumb'>
          <li>
            <Link to={`${baseRoute}${ROUTE.EXPLORE}`}>Manuals</Link>
            <Icon type='arrow-left' />
          </li>
          <li>
            <Link to={`${baseRoute}${ROUTE.SEARCH}`}>Search Results</Link>
            <Icon type='arrow-left' />
          </li>
          <li>{contentIndex[publicationId].title}</li>
        </ul>
      </Media>

      {isFetching ? (
        <Loader className='inverse' />
      ) : (
        <div className='topics'>
          <ul className='TopicsList'>
            {topicGroupItems.map((topic) => {
              const isFolder = topic.type === 'folder'
              const resourceKey = topic.resourceKey

              const BrandedListItem = currentBrand === BRAND_INFINITI ? InfinitiTopicListItem : TopicListItem

              return (
                <BrandedListItem
                  key={topic.id}
                  to={`${baseRoute}/search${generatePublicationLink({ isFolder, resourceKey })}`}
                  {...{ topic, searchQuery }}
                  onClick={(topic) => trackSearchResultsTopicClick(topic.title)}
                />
              )
            })}
          </ul>
        </div>
      )}
    </TopicLayout>
  )
}

PublicationGroup.propTypes = {
  baseRoute: PropTypes.string,
  isFetching: PropTypes.bool,
  searchQuery: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  searchTopics: PropTypes.shape({
    toJS: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      publicationId: PropTypes.string,
    }),
  }),
  contentIndex: PropTypes.shape({
    title: PropTypes.string,
  }),
  currentBrand: PropTypes.string,
}

const mapStateToProps = (state) => ({
  searchTopics: state.contents.searchTopics,
  contentIndex: state.contents.contentIndex,
  searchQuery: state.contents.searchQuery,
  currentBrand: state.vehicle.currentBrand,
})

export default connect(mapStateToProps)(PublicationGroup)
