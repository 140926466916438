import { isWarningLightsPublication } from './publication'

const WARNING_LIGHTS_ROOT_TOPIC_TITLES = ['icon glossary', 'warning & indicator lights']

export function getRootTopicForWarningLightsPublication(topics,publications) {
  const warningLightsPublication = publications?.find(publication => {
    const title = publication.get('title')
    return title?.toLowerCase().includes("warning") && title?.toLowerCase().includes("light") && title?.toLowerCase().includes("indicator")
  })
  const warningLightsPublicationId = warningLightsPublication.get('id')
  return topics.find(topic => {
    return topic.get('publication').get('publicationId') === warningLightsPublicationId && isWarningLightsRootTopic(topic.get('title'))
  })
}

/**
 * @param {string} title
 * @returns {boolean}
 */
export function isWarningLightsRootTopic(title) {
  return WARNING_LIGHTS_ROOT_TOPIC_TITLES.includes(title.toLowerCase())
}

/**
 *
 * @param {Node} el
 * @returns {boolean}
 */
export function isInternalLink(el) {
  return el.tagName.toLowerCase() === 'a' && el.classList.contains('xref') && el.dataset.scope === 'internal'
}

/**
 *
 * @param {Immutable.Map} topic
 * @returns {boolean}
 */
export function isPartOfWarningLightsPublication(topic) {
  return isWarningLightsPublication(topic.get('publication').toJS())
}
