import React from 'react'

import PropTypes from 'prop-types'
const Match = ({ onSelect, item }) => {
  return (
    <li className='PdfMatches__search-result' key={`${item.id}_${item.index}`} onClick={onSelect}>
      <div className='PdfMatches__search-result-page'>{`Page: ${item.index}`}</div>
      {item.searchHighlights.bodyText && (
        <div
          className='PdfMatches__search-result-match'
          dangerouslySetInnerHTML={{ __html: item.searchHighlights.bodyText[0] }}
        />
      )}
    </li>
  )
}

Match.propTypes = {
  onSelect: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number,
    searchHighlights: PropTypes.shape({
      bodyText: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
}

export default Match
