import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Immutable from 'immutable'
import Icon from './Icon'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTE } from '../config'

class BreadCrumb extends Component {
  static propTypes = {
    foldersIndex: ImmutablePropTypes.map.isRequired,
    activeFolder: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    inactive: PropTypes.bool,
    currentTopic: ImmutablePropTypes.map,
  }

  static defaultProps = {
    inactive: false,
  }

  renderFoldersPath = (activeFolder) => {
    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, baseRoute } = this.props
    const resourceKey = activeFolder.get('resourceKey')
    let parentFolder = null

    if (resourceKey) {
      parentFolder = foldersIndex.find((folder) => {
        return folder.get('contents').keySeq().contains(resourceKey)
      })
    }

    const foldersPath = parentFolder ? this.renderFoldersPath(parentFolder) : new Immutable.List()

    return foldersPath.push(
      <li key={resourceKey || BROWSE_ROOT_KEY}>
        <Link
          to={
            activeFolder.get('title')
              ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${resourceKey || ''}`
              : `${baseRoute}${ROUTE.EXPLORE}`
          }
        >
          {activeFolder.get('title') || 'Manuals'}
        </Link>
        <Icon type='arrow-left' />
      </li>,
    )
  }

  render() {
    const { activeFolder, inactive, currentTopic } = this.props

    return (
      <ul className={classNames('topics-breadcrumb', inactive && 'inactive')}>
        {this.renderFoldersPath(activeFolder)}
        {currentTopic && <li>{currentTopic.get('title')}</li>}
      </ul>
    )
  }
}

export default BreadCrumb
