import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import { ROUTE } from '../config'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { connect } from 'react-redux'

import Media from './Media'
import Icon from './Icon'

const ScreenHead = ({ title = '', back = null, backTitle = 'Back', children }) => {
  const { FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW } = getConfig()
  title = children || title

  const backLink = useMemo(() => {
    if (back) {
      if (typeof back === 'function') {
        return (
          <button type='button' name='back' onClick={back}>
            <Icon type='arrow-left' />
            {backTitle}
          </button>
        )
      } else {
        return (
          <Link to={back} replace>
            <Icon type='arrow-left' />
            {backTitle}
          </Link>
        )
      }
    }
  }, [back])

  return (
    <div className='screen-head'>
      <Media type='desktop'>
        <Link to={ROUTE.INDEX} className='screen-logo' />
      </Media>
      <Media type='desktop'>
        <h1 className='screen-title'>{title}</h1>
      </Media>

      <Media type='mobile'>
        {back ? (
          <div className={classNames('screen-back', backTitle.length > 25 && 'title-long')}>{backLink}</div>
        ) : (
          <Link to={ROUTE.GUIDE} className='screen-title'>
            {title}
          </Link>
        )}
      </Media>

      {FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW && <div className='content-preview-mode'>Draft content preview</div>}
    </div>
  )
}

ScreenHead.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  backTitle: PropTypes.string,
}

function mapStateToProps({ vehicle }) {
  return {
    currentBrand: vehicle.currentBrand,
  }
}

export default connect(mapStateToProps, null)(withRouter(ScreenHead))
