import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getConfig } from 'tw-oi-core'
import {
  getPathAlias,
  getVehiclesYears,
  getVehicleForBrand,
  getVehicleModels,
  getModelGroups,
  getLatestYear,
  getVehiclesForYear,
} from 'tw-oi-core/utils/vehicle'
import { setVehicleProgram } from 'tw-oi-core/services/analytics'
import { withoutCommingSoonModels } from '../utils/vehicles'

import { ROUTE, MESSAGE, VEHICLE_FAMILIES } from '../config'

import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import VehicleYearSelector from '../components/VehicleYearSelector'
import VehicleList from '../components/VehicleList'
import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'

import '../styles/Vehicles.scss'

class Vehicles extends Component {
  static propTypes = {
    vehicle: PropTypes.shape({
      currentModel: PropTypes.string,
      currentYear: PropTypes.string,
      currentGroup: PropTypes.string,
      currentBrand: PropTypes.string,
      vehicles: PropTypes.array,
      fetching: PropTypes.bool,
      error: PropTypes.object,
    }).isRequired,
    VehicleActions: PropTypes.shape({
      getVehicles: PropTypes.func.isRequired,
      setVehicleProgram: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        year: PropTypes.string,
        group: PropTypes.string,
      }).isRequired,
    }).isRequired,
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      models: [],
      years: [],
      familyGroup: null,
      ready: false,
      found: false,
      currentYear: props.vehicle.currentYear,
      currentGroup: props.vehicle.currentGroup,
      currentYearVehicles: [],
    }
  }

  /**
   * Stores currently selected year
   *
   * @param {String} year
   * @returns {Promise}
   */
  setVehicleYear = (year) => {
    return this.setState({
      currentYear: year,
    })
  }

  /**
   * Saves currently selected group
   *
   * @param {String} group
   * @returns {Promise}
   */
  setVehicleGroup = (group) => {
    return this.setState({
      currentGroup: group,
    })
  }

  /**
   * Loads all vehicles and handles the initialization of specific year
   *
   * The year is either specified explicitly or via the most recent year of available content
   *
   * @param {String} year
   * @param {String} group
   * @returns {*}
   */
  loadVehicles = (year = null, group = '') => {
    const { vehicles, currentBrand, fetching } = this.props.vehicle
    const { currentYear, currentGroup } = this.state
    const { TEMPORARY_GROUPS } = getConfig()

    // load vehicles if not loaded and not currently loading
    if (vehicles === null) {
      if (!fetching) this.props.VehicleActions.getVehicles()
      return
    }

    // check years availability
    const brandVehicles = getVehicleForBrand(vehicles, currentBrand)
    const years = getVehiclesYears(brandVehicles)

    // in case the year isn't available, then set the active year by taking the most recent one from the content
    // or by using the one which is available
    if (!year) {
      // select the latest year (without "coming soon" vehicles)
      year = getLatestYear(getVehiclesYears(withoutCommingSoonModels(brandVehicles)))
    }

    if (years.indexOf(year) === -1) {
      return this.setState({ years, ready: true, found: false })
    }

    if (year !== currentYear) {
      this.setVehicleYear(year)
    }

    // check group availability (for currentYear)
    const models = getVehicleModels(brandVehicles, year)
    const currentYearVehicles = getVehiclesForYear(brandVehicles, year)
    const groups = getModelGroups(currentYearVehicles)
    const groupName = groups.find((element) => getPathAlias(element) === group)

    this.setState({ currentYearVehicles })

    // return early if group isn't available
    if (group && !groupName) {
      if (year in VEHICLE_FAMILIES && typeof VEHICLE_FAMILIES[year] === 'object' && group in VEHICLE_FAMILIES[year]) {
        let familyModels = models.filter((model) => VEHICLE_FAMILIES[year][group]['models'].indexOf(model) !== -1)
        const familyModel = VEHICLE_FAMILIES[year][group]['name']
        const familyGroup = TEMPORARY_GROUPS[familyModel]

        if (familyModels.length > 0) {
          return this.setState({ familyGroup, familyModel, years, models: familyModels, ready: true, found: true })
        } else {
          return this.setState({ years, models, ready: true, found: false })
        }
      } else {
        return this.setState({ years, models, ready: true, found: false })
      }
    }

    // update year && group
    if (year !== currentYear) this.setVehicleYear(year)
    if (groupName !== currentGroup) this.setVehicleGroup(groupName || null)

    return this.setState({
      familyGroup: null,
      familyModel: null,
      models,
      years,
      ready: true,
      found: true,
    })
  }

  componentDidMount() {
    const {
      match: {
        params: { year, group },
      },
    } = this.props
    this.loadVehicles(year, group)
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { year: prevYear, group: prevGroup },
      },
      vehicle: { vehicles: prevVehicles },
    } = prevProps
    const {
      match: {
        params: { year, group },
      },
      vehicle: { vehicles },
    } = this.props
    if (prevYear !== year || prevGroup !== group || (prevVehicles === null && vehicles !== null)) {
      this.loadVehicles(year, group)
    }
  }

  /**
   * Updates analytics dimension to reflect the selected model
   *
   * @param {String} model
   */
  setVehicleModel(model) {
    const { currentYear } = this.state
    const { currentBrand } = this.props.vehicle

    // update analytics context
    setVehicleProgram(currentYear, currentBrand, model)
  }

  render() {
    const { currentBrand, currentModel, error } = this.props.vehicle
    const { media } = this.props.user
    const { years, models, familyModel, currentYearVehicles } = this.state
    const { currentYear, currentGroup } = this.state

    if (error) {
      return <ErrorMessage retryAction={() => this.loadVehicles()} />
    }

    if (!this.state.ready) {
      return <Loader className='inverse' />
    }

    return (
      <div className='Vehicles'>
        <VehicleYearSelector
          currentYear={currentYear}
          years={years}
          onSelected={this.setVehicleYear}
          slider={!media.isDesktop && years.length > 4}
          currentBrand={currentBrand}
        />
        {this.state.found ? (
          <VehicleList
            currentYear={currentYear}
            currentGroup={currentGroup}
            currentModel={currentModel}
            models={models}
            familyModel={familyModel}
            setVehicleModel={(model) => this.setVehicleModel(model)}
            currentYearVehicles={currentYearVehicles}
          />
        ) : (
          <ErrorMessage
            className='inverse'
            title={MESSAGE.ERROR_VEHICLE_NOT_FOUND}
            retryAction={ROUTE.VEHICLES}
            retryTitle='Select vehicle'
            message={MESSAGE.ERROR_VEHICLE_RETRY}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps({ vehicle, user }) {
  return { vehicle, user }
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles)
