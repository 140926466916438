import React, { PureComponent, createRef } from 'react'
import { connect } from 'react-redux'
import { pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Loader from '../Loader'
import PdfPreview from '../PdfPreview'
import Media from '../Media'
import Icon from '../Icon'

import { PlusIcon, MinusIcon, OpenIcon } from './icons'
import SearchResults from './SearchResults'
import RoundBtn from './RoundBtn'
import { extractHightlightWords } from './utils'

import './SearchResults/SearchResults.scss'
import './PdfViewer.scss'
import { ROUTE } from '../../config'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class PdfViewer extends PureComponent {
  static propTypes = {
    file: PropTypes.string,
    searchPagesByPublication: PropTypes.object,
    selectedPageIndex: PropTypes.number,
    onSearchResultSelect: PropTypes.func,
    selectedSearchResult: PropTypes.shape({
      searchHighlights: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    hasPagesPreview: PropTypes.bool,
    pages: PropTypes.arrayOf(PropTypes.object),
    baseRoute: PropTypes.string,
    match: PropTypes.object,
    publication: PropTypes.object,
    userMedia: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      viewerContainerHeight: null,
      hasPagesPreview: false,
      isPagesPreviewOpen: false,
      scale: 1.5,
    }

    this.viewerContainer = createRef()
  }

  componentDidMount() {
    this.setState({
      viewerContainerHeight: this.viewerContainer.current.scrollHeight,
    })

    // this is for correct pdf render in iframe
    document.querySelector('#root .container').style.height = '100%'

    if (!this.props.userMedia.isDesktop) {
      document.querySelector('#root .screen-container').style.height = '600px'
    }
  }

  componentWillUnmount() {
    document.querySelector('#root .container').style.height = ''

    if (!this.props.userMedia.isDesktop) {
      document.querySelector('#root .screen-container').style.height = ''
    }
  }

  handleZoomIn = () => {
    if (this.state.scale < 5) {
      this.setState({ scale: this.state.scale + 0.1 })
    }
  }

  handleZoomOut = () => {
    if (this.state.scale > 0.5) {
      this.setState({ scale: this.state.scale - 0.1 })
    }
  }

  handleTogglePagesPreviewOpen = () => this.setState({ isPagesPreviewOpen: !this.state.isPagesPreviewOpen })

  handleSearchResultSelect = (searchResult) => {
    this.props.onSearchResultSelect(searchResult)
    this.setState({ isPagesPreviewOpen: false })
  }

  render() {
    const { file, pages, selectedPageIndex, selectedSearchResult, hasPagesPreview, baseRoute, match, publication } =
      this.props
    const { scale, isPagesPreviewOpen, viewerContainerHeight } = this.state

    return (
      <div className='PdfViewer' ref={this.viewerContainer}>
        <Media type='desktop'>
          <ul className='topics-breadcrumb'>
            <li>
              <Link to={`${baseRoute}${ROUTE.EXPLORE}`}>Manuals</Link>
              <Icon type='arrow-left' />
            </li>

            {match.params.referrer === ROUTE.SEARCH && (
              <li>
                <Link to={`${baseRoute}${ROUTE.SEARCH}`}>{match.params.referrer.substring(1)}</Link>
                <Icon type='arrow-left' />
              </li>
            )}

            <li>{publication.get('title')}</li>
          </ul>
        </Media>

        <header className='PdfViewer__actions'>
          <div className='PdfViewer__scale'>
            <RoundBtn name='zooom in' className='PdfViewer__scale-btn' onClick={this.handleZoomIn}>
              <PlusIcon />
            </RoundBtn>
            <RoundBtn name='zoom out' className='PdfViewer__scale-btn' onClick={this.handleZoomOut}>
              <MinusIcon />
            </RoundBtn>
          </div>

          {hasPagesPreview && (
            <RoundBtn name='page preiew' className='PdfViewer__list-btn' onClick={this.handleTogglePagesPreviewOpen}>
              <OpenIcon />
            </RoundBtn>
          )}
        </header>

        {hasPagesPreview && (
          <SearchResults
            isOpen={isPagesPreviewOpen}
            items={pages}
            selectedItem={selectedSearchResult}
            onSelect={this.handleSearchResultSelect}
            onClose={this.handleTogglePagesPreviewOpen}
          />
        )}

        <PdfPreview
          file={file}
          loader={<Loader />}
          scale={scale}
          viewerContainerHeight={viewerContainerHeight}
          selectedPageIndex={selectedPageIndex}
          highlightWords={selectedSearchResult ? extractHightlightWords(selectedSearchResult.searchHighlights) : []}
          onInitPageSizeRetrieved={({ width }) => {
            const scale = (window.innerWidth - 16) / width
            if (scale < 1.3) { // 1.3 to take tablets into account
              this.setState({ scale })
            }
          }}
        />
      </div>
    )
  }
}

function mapStateToProps({ user }) {
  return {
    userMedia: user.media,
  }
}

export default connect(mapStateToProps, null)(PdfViewer)
