import React from 'react'
import PropTypes from 'prop-types'

const VideoMarkers = ({ items, onClick }) => {
  /**
   * Orders the markers by timestamp
   *
   * @param {Array} items
   * @returns {Array}
   */
  const getOrderedMarkers = (items) => {
    return [...items].sort((a, b) => a.timestamp - b.timestamp)
  }

  const formatTime = (duration) => {
    const minutes = (Math.floor(duration / 60) + '').padStart(2, 0)
    const seconds = ((duration % 60) + '').padStart(2, 0)
    return `${minutes}:${seconds}`
  }

  return (
    <div className='video-markers-container'>
      <div className='video-markers'>
        {getOrderedMarkers(items).map((item) => {
          return (
            <button
              type='button'
              name='video marker timestamp'
              key={item.timestamp}
              onClick={() => {
                onClick(item.timestamp)
              }}
            >
              {item.title} ({formatTime(item.timestamp)})
            </button>
          )
        })}
      </div>
    </div>
  )
}

VideoMarkers.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default VideoMarkers
