import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getConfig } from 'tw-oi-core'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getDefaultHotSpot } from 'tw-oi-core/utils/contents'
import { setVehicleProgram } from 'tw-oi-core/services/analytics'

import { MESSAGE, ROUTE } from '../config'

import * as VehicleActions from 'tw-oi-core/actions/VehicleActions'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import { getPathAlias } from 'tw-oi-core/utils/vehicle'

import Explore from './Explore/'
import Browse from './Browse'
import Topic from './Topic'
import Favorites from './Favorites'
import Search from './Search'
import PdfPublication from './PdfPublication'
import PdfPublicationMatches from './PdfPublicationMatches'
import PublicationGroup from './PublicationGroup'
import Loader from '../components/Loader'
import ErrorMessage from '../components/ErrorMessage'
import ComingSoon from '../components/ComingSoon'
import ScreenHead from '../components/ScreenHead'
import { getVehiclesYears, getVehicleForBrand, getVehicleModels } from 'tw-oi-core/utils/vehicle'

import api from 'tw-oi-core/services/ContentDelivery'

import '../styles/Guide.scss'

export class Guide extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      params: PropTypes.shape({
        year: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    contents: PropTypes.shape({
      topics: ImmutablePropTypes.list,
      error: PropTypes.string,
      imageMaps: PropTypes.array,
      imageMapBlobs: PropTypes.object,
      contentsUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    vehicle: PropTypes.shape({
      vehicles: PropTypes.array,
      currentBrand: PropTypes.string,
      currentYear: PropTypes.string,
      currentGroup: PropTypes.string,
      currentModel: PropTypes.string,
      error: PropTypes.object,
      fetching: PropTypes.bool,
    }).isRequired,
    VehicleActions: PropTypes.shape({
      getVehicles: PropTypes.func.isRequired,
      setVehicleProgram: PropTypes.func.isRequired,
    }).isRequired,
    ContentsActions: PropTypes.shape({
      getContents: PropTypes.func.isRequired,
      getImageMaps: PropTypes.func.isRequired,
    }),
  }

  constructor(props) {
    super(props)

    this.state = {
      ready: false,
      found: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.found && !nextProps.contents.topics) {
      return {
        ready: false,
      }
    }
    return null
  }

  /**
   * Loads all vehicles and handles loading and processing of content for selected vehicle
   *
   * @param {String} year
   * @param {String} model
   * @return {*}
   */
  loadVehicleContent = (year, model) => {
    const { REQUEST_GROUP_IMAGEMAPS, REQUEST_ID_CONTENTS } = getConfig()
    const { DEFAULT_LOCALE } = getConfig()
    const { vehicles, currentBrand, currentYear, currentModel, fetching: fetchingVehicles } = this.props.vehicle
    const { topics, imageMaps, imageMapBlobs } = this.props.contents

    // #0 load vehicles if doesn't exist
    if (vehicles === null) {
      if (!fetchingVehicles) this.props.VehicleActions.getVehicles()
      return
    }

    // #1 check years availability
    const brandVehicles = getVehicleForBrand(vehicles, currentBrand)
    const years = getVehiclesYears(brandVehicles)

    if (years.indexOf(year) === -1) {
      return this.setState({ ready: true, found: false })
    }

    // #2 check model availability (for currentYear)
    const models = getVehicleModels(brandVehicles, year)
    const modelName = models.find(element => getPathAlias(element) === model)

    if (!modelName) {
      return this.setState({ ready: true, found: false })
    }

    // #3 update vehicle program state (year, group, model)
    if (year !== currentYear || modelName !== currentModel) {
      api.cancelRequest(REQUEST_GROUP_IMAGEMAPS)
      api.cancelRequest(REQUEST_ID_CONTENTS)
      return this.props.VehicleActions.setVehicleProgram(year, modelName)
    }

    //#4 load vehicle content for current vehicle if not loaded or was previosly flushed
    if (topics === null) {
      return this.props.ContentsActions.getContents(currentBrand, year, modelName, DEFAULT_LOCALE)
    } else {
      this.setState({ ready: true, found: true })
    }

    // preload vehicle image maps
    if (imageMapBlobs === null && imageMaps !== null) {
      this.props.ContentsActions.getImageMaps(imageMaps)
    }

    // update the google analytics context so that selected vehicle program can be tracked as custom dimension
    setVehicleProgram(year, currentBrand, modelName)
  }

  renderWithProps = (Component, additionalProps) => props => {
    return <Component {...props} {...additionalProps} />
  }

  renderWithBaseRoute = Component => props => {
    return <Component {...props} baseRoute={this.props.match.url} />
  }

  componentDidMount() {
    const { year, model } = this.props.match.params
    this.loadVehicleContent(year, model)
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { year: prevYear, model: prevModel },
      },
      vehicle: { vehicles: prevVehicles, currentModel: prevCurrentModel, currentYear: prevCurrentYear },
      contents: { contentsUpdated: prevContentsTimestamp },
    } = prevProps
    const {
      match: {
        params: { year, model },
      },
      vehicle: { vehicles, currentModel, currentYear },
      contents: { contentsUpdated: contentsTimestamp },
    } = this.props

    // trigger loading contents if vehicles list was loaded, model/year (incl URL) changed or vehicle data was loaded
    if (
      (prevVehicles === null && vehicles !== null) ||
      year !== prevYear ||
      model !== prevModel ||
      currentYear !== prevCurrentYear ||
      currentModel !== prevCurrentModel ||
      contentsTimestamp !== prevContentsTimestamp
    ) {
      this.setState({ ready: false, found: false })
      this.loadVehicleContent(year, model)
    }
  }

  render() {
    const { COMING_SOON_CONTENT, FEATURE_ENABLED_FAVORITES, FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW } = getConfig()
    const {
      match,
      match: {
        params: { year, model },
      },
    } = this.props
    const { error: errorVehicles } = this.props.vehicle
    const { error: errorContents, topics, imageMaps } = this.props.contents

    if (errorVehicles || errorContents) {
      return <ErrorMessage className='inverse' retryAction={() => this.loadVehicleContent(year, model)} />
    }

    if (!this.state.ready) {
      return <Loader className='inverse' />
    }

    if (!this.state.found) {
      return (
        <ErrorMessage
          className='inverse'
          title={MESSAGE.ERROR_VEHICLE_NOT_FOUND}
          retryAction={ROUTE.VEHICLES}
          retryTitle='Select vehicle'
          message={MESSAGE.ERROR_VEHICLE_RETRY}
        />
      )
    }

    // display "Coming soon" page when model/year is in "Coming soon" list
    if (
      !topics.size &&
      COMING_SOON_CONTENT.filter(item => getPathAlias(item.model) === model && item.year === year).length !== 0
    ) {
      return (
        <div className='Guide'>
          <ComingSoon />
        </div>
      )
    }

    // If there is no data in Draft Preview mode then show a message that current model doesn't contains draft content
    if (!topics.size && FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW) {
      return (
        <div className='Guide'>
          <div className='Topics'>
            <ScreenHead></ScreenHead>
            <div className='screen-content'>
              <ErrorMessage
                className='inverse'
                title='No draft content available'
                retryAction={ROUTE.VEHICLES}
                retryTitle='Select vehicle'
                message={MESSAGE.ERROR_VEHICLE_RETRY}
              />
            </div>
          </div>
        </div>
      )
    }

    // Auto-select hot spot
    let exploreAvailable = false
    let defaultImageMap = null
    let defaultHotSpot = null
    if (imageMaps && imageMaps.length) {
      exploreAvailable = true
      defaultImageMap = imageMaps[0].title
      defaultHotSpot = getDefaultHotSpot(imageMaps[0].hotspots).toString()
      // TODO: Move "Explore" routing to sub-router?
    }

    const allReferrers = `${ROUTE.BROWSE}|${ROUTE.FAVORITES}|${ROUTE.SEARCH}`

    return (
      <div className='Guide'>
        <Switch>
          {exploreAvailable && defaultImageMap && defaultHotSpot && (
            <Redirect
              exact
              from={`${match.path}${ROUTE.EXPLORE}`}
              to={`${match.url}${ROUTE.EXPLORE}/${defaultImageMap}/${defaultHotSpot}`}
            />
          )}
          <Route
            exact
            path={`${match.path}${ROUTE.BROWSE}(${ROUTE.FOLDER})?/:folderId?`}
            render={this.renderWithBaseRoute(Browse)}
          />
          <Route
            exact
            path={`${match.path}${ROUTE.EXPLORE}/:imageMapType?/:hotSpotIndex?`}
            render={this.renderWithBaseRoute(Explore)}
          />
          <Route
            exact
            path={`${match.path}:referrer(${allReferrers})${ROUTE.TOPIC}/:topicId/:time?`}
            render={this.renderWithBaseRoute(Topic)}
          />
          <Route
            exact
            path={`${match.path}:referrer(${allReferrers})${ROUTE.PDF_PUBLICATION_MATCHES}`}
            render={this.renderWithBaseRoute(PdfPublicationMatches)}
          />
          <Route
            exact
            path={`${match.path}:referrer(${allReferrers})${ROUTE.PUBLICATION_GROUP}`}
            render={this.renderWithBaseRoute(PublicationGroup)}
          />
          <Route
            exact
            path={`${match.path}:referrer(${allReferrers})${ROUTE.PDF_PUBLICATION}`}
            render={this.renderWithBaseRoute(PdfPublication)}
          />
          <Route
            exact
            path={`${match.path}:referrer(${ROUTE.EXPLORE})/:imageMapType?/:hotSpotIndex?${ROUTE.TOPIC}/:topicId/:time?`}
            render={this.renderWithBaseRoute(Topic)}
          />
          {FEATURE_ENABLED_FAVORITES && <Route exact path={`${match.path}${ROUTE.FAVORITES}`} component={Favorites} />}
          <Route exact path={`${match.path}${ROUTE.SEARCH}`} render={this.renderWithBaseRoute(Search)} />
          <Redirect from={match.path} to={match.url} /> {/*Redirect to Guide root if other not found:*/}
        </Switch>
      </div>
    )
  }
}

function mapStateToProps({ vehicle, contents }) {
  return { contents, vehicle }
}

function mapDispatchToProps(dispatch) {
  return {
    VehicleActions: bindActionCreators(VehicleActions, dispatch),
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Guide)
