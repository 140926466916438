import React, { Component } from 'react'
import { Provider } from 'react-redux'

import configureStore from 'tw-oi-core/store/configureStore'
import FullStoryTracker from 'tw-oi-core/utils/fullstory'
import { initCore } from 'tw-oi-core'
import { PersistGate } from 'redux-persist/integration/react'

import Main from './containers/Main'
import Loader from './components/Loader'
import ThemeContainer from './containers/ThemeContainer'

import { CORE, APP_VERSION } from './config'
import { CORE_VERSION } from 'tw-oi-core/config'

export class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    const config = this.getConfigWithErrorLogEndPoint()

    // load environment & project specific runtime config
    initCore(config).then(() => {
      const { store, persistor } = configureStore()
      this.store = store
      this.persistor = persistor
      this.setState({ loading: false })
    })
  }

  getConfigWithErrorLogEndPoint() {
    if (!CORE.ERROR_LOG_ENDPOINT) {
      return CORE
    }

    let origin

    if (!window.location.origin) {
      origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    } else {
      origin = window.location.origin
    }

    const ERROR_LOG_ENDPOINT = `${origin}${CORE.ERROR_LOG_ENDPOINT}`
    return { ...CORE, ERROR_LOG_ENDPOINT }
  }

  render() {
    if (this.state.loading) return <Loader />

    return (
      <Provider store={this.store}>
        <PersistGate loading={null} persistor={this.persistor}>
          <ThemeContainer>
            <React.StrictMode>
              <Main />
            </React.StrictMode>
            <span className='version'>{`app: ${APP_VERSION}; core: ${CORE_VERSION}`}</span>
            <FullStoryTracker />
          </ThemeContainer>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
