import React from 'react'

import { CloseIcon } from '../../icons'

import './CloseBtn.scss'

const CloseBtn = (props) => (
  <button type='button' name='close' className='CloseBtn' {...props}>
    <CloseIcon />
  </button>
)

export default CloseBtn
