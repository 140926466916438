import React from 'react'
import qs from 'qs'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Loader from '../../components/Loader'
import TopicLayout from '../../components/TopicLayout'
import SortSelector from '../../components/PdfViewer/SearchResults/SortSelector'
import { SORT_ORDER, useSorting } from '../../components/PdfViewer/SearchResults/SortSelector/utils'
import { ROUTE } from '../../config'
import Media from '../../components/Media'
import Icon from '../../components/Icon'

import withSearchResults from '../PdfPublication/withSearchResults'

import Match from './Match'
import './PdfMatches.scss'

const PdfPublicationMatches = ({
  baseRoute,
  searchResults,
  isFetching,
  resourceKey,
  searchQuery,
  history,
  publication,
}) => {
  const [sortedItems, sortOrder, setSortOrder] = useSorting(SORT_ORDER.RANK, searchResults || [])

  return (
    <TopicLayout className='PdfMatches' backRoute={() => history.goBack()} backTitle={publication.get('title')}>
      {isFetching ? (
        <Loader className='inverse' />
      ) : (
        <div className='PdfMatches__container'>
          <Media type='desktop'>
            <ul className='topics-breadcrumb'>
              <li>
                <Link to={`${baseRoute}${ROUTE.EXPLORE}`}>Manuals</Link>
                <Icon type='arrow-left' />
              </li>

              <li>
                <Link to={`${baseRoute}${ROUTE.SEARCH}`}>Search</Link>
                <Icon type='arrow-left' />
              </li>
              <li>{publication.get('title')}</li>
            </ul>
          </Media>

          <div className='PdfMatches__header'>
            <SortSelector sortOrder={sortOrder} onSortOrderSelect={setSortOrder} />
          </div>

          <ul className='PdfMatches__search-results'>
            {sortedItems.map((item) => (
              <Match
                key={item.index}
                onSelect={() =>
                  history.push(
                    `${baseRoute}/search${generatePath(ROUTE.PDF_PUBLICATION, { resourceKey })}?${qs.stringify({
                      q: searchQuery,
                      page: item.index,
                    })}`,
                  )
                }
                {...{ item }}
              />
            ))}
          </ul>
        </div>
      )}
    </TopicLayout>
  )
}

PdfPublicationMatches.propTypes = {
  baseRoute: PropTypes.string,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  resourceKey: PropTypes.string,
  searchQuery: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  publication: PropTypes.shape({
    get: PropTypes.func,
  }),
}

export default withSearchResults(PdfPublicationMatches)
