/**
 * Splits an array into subarrays with given chunk size
 *
 * e.g. splitArrayIntoChunks([1, 2, 3, 4, 5], 2) => [[1, 2], [3, 4], [5]]
 *
 * https://stackoverflow.com/questions/8495687/split-array-into-chunks#comment84212474_8495740
 *
 * @param {Array} array
 * @param {number} chunkSize
 * @returns {Array}
 */
export const splitArrayIntoChunks = (array, chunkSize) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map(begin => array.slice(begin, begin + chunkSize))
