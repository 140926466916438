import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../components/Icon'

import { BRAND_INFINITI } from '../config'

const ImageMapSelector = ({ items, active, onChange, currentBrand }) => {
  const isInfiniti = currentBrand === BRAND_INFINITI

  const iconNames = {
    Exterior: {
      fileName: isInfiniti ? 'explore360Infiniti' : 'explore360',
      label: 'General',
    },
    Cargo: {
      fileName: isInfiniti ? 'exploreRearInfiniti' : 'exploreBack',
      label: 'Cargo',
    },
    FrontSeat: {
      fileName: isInfiniti ? 'exploreFrontInfiniti' : 'exploreFront',
      label: 'Front',
    },
    WideCabin: {
      fileName: isInfiniti ? 'seatInfiniti' : 'exploreFront',
      label: 'Front seats',
    },
    RearSeat: {
      fileName: isInfiniti ? 'seatInfiniti' : 'exploreRear',
      label: 'Back seats',
    },
    Rear: {
      fileName: isInfiniti ? 'exploreRearInfiniti' : 'exploreBack',
      label: 'Rear',
    },
    DriverSeat: {
      fileName: isInfiniti ? 'driverSeatInfiniti' : 'exploreWheel',
      label: 'Driver seat',
    },
    Hatch: {
      fileName: isInfiniti ? 'exploreRearInfiniti' : 'exploreHatch',
      label: 'Hatch',
    },
    TruckBed: {
      fileName: isInfiniti ? 'exploreRearInfiniti' : 'explorePickup',
      label: 'Truck bed',
    },
  }

  return (
    <div className='image-map-selector'>
      {items.map((name) => {
        return (
          <button
            type='button'
            name='image map spot'
            key={name}
            className={classNames(name === active && 'active')}
            onClick={() => onChange(name)}
          >
            <Icon type={iconNames[name].fileName} />
            <span className='button-name'>{iconNames[name].label}</span>
          </button>
        )
      })}
    </div>
  )
}

ImageMapSelector.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  currentBrand: PropTypes.string.isRequired,
}

function mapStateToProps({ vehicle }) {
  return { currentBrand: vehicle.currentBrand }
}

export default connect(mapStateToProps)(ImageMapSelector)
