import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from '../components/Loader'
import { ROUTE, BRAND_NISSAN } from '../config'
import Icon from '../components/Icon'
import { generatePublicationLink, isWarningLightsPublication } from '../utils/publication'
import { getRootTopicForWarningLightsPublication } from '../utils/topics'

import '../styles/TopicsList.scss'

// note: warning lights pub is checked with publicationSubType
const publicationsTypeToIconsMap = {
  UG: 'quick-guide',
  QRG: 'quick-guide',
  OG: 'quick-guide',
  MNT: 'maintenance',
  OM: 'owners-manual',
  NM: 'navigation-manual',
  WTY: 'folder',
  RAD: 'folder',
  VS: 'folder',
}

const TopicsList = ({
  topics,
  publications,
  emptyText = 'No topics found',
  baseRoute,
  className,
  isDesktop = false,
  onClick,
  allTopics,
  contentIndex,
  currentBrand,
}) => {
  const topicElements = useMemo(() => {
    const publicationsList = publications ? publications.toJS() : []
    let topicsList = topics

    let topicElements = []
    topicsList.forEach((topic, key) => {
      const isFolder = topic.get('type') === 'folder'
      const isPublication = topic.get('type') === 'publication'
      const resourceKey = topic.get('resourceKey')
      const hasVideo = !!topic.get('video')
      const hasImage = false //TODO: check for images in topic

      const isPdfPublication = publicationsList.some(
        (publication) => publication.resourceKey === resourceKey && !!publication.bodyPdf,
      )

      let link = `${baseRoute}${generatePublicationLink({ isPdfPublication, isFolder, resourceKey })}`

      let publicationIcon = ''
      if (isPublication) {
        const pubInfo = contentIndex[topic.get('id')]
        const isWarningLightsPub = isWarningLightsPublication(pubInfo)
        if (isWarningLightsPub) {
          const warnLightsRootTopic = getRootTopicForWarningLightsPublication(allTopics,publications)
          link = `${baseRoute}${ROUTE.TOPIC}/${warnLightsRootTopic.get('resourceKey')}`
        }

        const publicationType = pubInfo.publicationType
        publicationIcon = isWarningLightsPub
          ? 'warning-lights'
          : publicationsTypeToIconsMap[publicationType] || 'publication-book'
      }

      if (currentBrand === BRAND_NISSAN) {
        if (publicationIcon === 'owners-manual') {
          publicationIcon = 'owners-manual-nissan'
        }
        if (publicationIcon === 'quick-guide') {
          publicationIcon = 'quick-guide-nissan'
        }
      }

      topicElements.push(
        <li key={key + 1}>
          <Link
            className='item-inner'
            to={link}
            onClick={() => {
              onClick && onClick(topic)
            }}
          >
            <div className='item-title'>{topic.get('title')}</div>
            <div className='item-iconbar'>
              {hasVideo && <Icon type='list-film' />}
              {hasImage && <Icon type='list-image' />}
              {isPublication && <Icon type={publicationIcon} />}
              {!isFolder && !isPublication && !hasVideo && !hasImage && <Icon type='list-topic' />}
              {!isDesktop && isFolder && <Icon type='arrow-left' />}
              {isDesktop && isFolder && <Icon type='folder' />}
            </div>
          </Link>
        </li>,
      )
      return true
    })

    return topicElements
  }, [topics, publications])

  if (topics === null || topics.size === 0) {
    return (
      <Loader type='status' className='inverse'>
        {emptyText}
      </Loader>
    )
  }

  return <ul className={classNames('TopicsList', className)}>{topicElements}</ul>
}

TopicsList.propTypes = {
  topics: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
  publications: ImmutablePropTypes.list,
  onBookmarkClick: PropTypes.func,
  referrer: PropTypes.oneOf([ROUTE.BROWSE, ROUTE.FAVORITES, ROUTE.EXPLORE, ROUTE.SEARCH]),
  emptyText: PropTypes.string,
  baseRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
  onClick: PropTypes.func,
  allTopics: ImmutablePropTypes.list,
  contentIndex: PropTypes.shape({
    publicationType: PropTypes.string,
  }),
  currentBrand: PropTypes.string,
}

const mapStateToProps = (state) => ({
  publications: state.contents.publications,
  allTopics: state.contents.topics,
  contentIndex: state.contents.contentIndex,
  currentBrand: state.vehicle.currentBrand,
})

export default connect(mapStateToProps)(TopicsList)
