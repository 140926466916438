import React, { useRef, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { identity } from 'lodash'

import Icon from '../Icon'
import { trimSearchQuery } from 'tw-oi-core/utils/ui'

import { ROUTE, UI } from '../../config'

import './SearchInput.scss'

const SearchInput = ({
  onChange = identity,
  onToggle = identity,
  searchQuery = '',
  autoFocus = true,
  onFocus = identity,
  history,
  baseRoute,
}) => {
  const inputRef = useRef(null)
  const [query, setQuery] = useState(searchQuery)

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        if (inputRef) {
          // set carret position to the end of searchQuery
          if (query) {
            let caretPos = query.length

            if (typeof inputRef.createTextRange !== 'undefined') {
              let range = inputRef.createTextRange()
              range.move('character', caretPos)
              range.select()
            } else {
              if (typeof inputRef.selectionStart !== 'undefined') {
                inputRef.current?.focus()
                inputRef.setSelectionRange(caretPos, caretPos)
              } else {
                inputRef.current?.focus()
              }
            }
          } else inputRef.current?.focus()
        }
      }, UI.ANIMATION_DELAY)
    }
  }, [])

  const clearSearchQuery = () => {
    setQuery('')
    onChange('')
  }

  const handleChange = (event) => {
    const query = trimSearchQuery(event.target.value)
    setQuery(query)

    const len = query.length
    if (len > 0 && len < UI.SEARCH_MIN_CHARACTERS) {
      return
    }

    onChange(query)
  }

  const handleFocus = () => {
    if (onFocus) onFocus()
    if (onToggle) onToggle(true)
  }

  const handleBlur = () => {
    if (onToggle) setTimeout(() => onToggle(false), UI.FRAME_DELAY)
  }

  const handleCloseClick = () => {
    clearSearchQuery()
    history.push(`${baseRoute}${ROUTE.EXPLORE}`)
  }

  return (
    <div className='SearchInput'>
      <Icon className='SearchInput__icon-pre' type='search' />
      <input
        name='search'
        type='text'
        ref={inputRef}
        value={query}
        placeholder='Search Manuals & Guides'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      <Icon onClick={handleCloseClick} type='close' />
    </div>
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onToggle: PropTypes.func,
  searchQuery: PropTypes.string,
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  history: PropTypes.object,
  baseRoute: PropTypes.string,
}

export default withRouter(SearchInput)
