import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Favicon from 'react-favicon'
import classNames from 'classnames'

import '../styles/App.scss'

const ThemeContainer = ({ currentBrand, children }) => {
  const brand = currentBrand.toLowerCase()

  return (
    <div className={classNames('container', brand ? brand : 'generic')}>
      <Favicon url={`${process.env.PUBLIC_URL}/images/favicon${brand ? '_' + brand : ''}.ico`} />
      {children}
    </div>
  )
}

ThemeContainer.propTypes = {
  currentBrand: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.func]),
}

function mapStateToProps({ vehicle: { currentBrand } }) {
  return { currentBrand }
}

export default connect(mapStateToProps)(ThemeContainer)
