import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createBrowserHistory } from 'history'
import { useMediaQuery } from 'react-responsive'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import { initGoogleAnalytics, setApplicationType, trackScreenView } from 'tw-oi-core/services/analytics'
import { getPathAlias, getModelUrl, getModelGroups } from 'tw-oi-core/utils/vehicle'
import { getGaCode } from 'tw-oi-core/utils/common'

import { breakpoints } from '../components/Media'

import Vehicles from './Vehicles'
import Guide from './Guide'
import Brands from './Brands'
import ErrorMessage from '../components/ErrorMessage'

import { GA_APPLICATION_TYPE_MOBILE, GA_APPLICATION_TYPE_WEB, ROUTE, MESSAGE, VEHICLE_FAMILIES, UI } from '../config'

import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

// isMobile variable for some reason gives false
const detectMobileForIframe = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

let prevMobileHeight = null

const Main = ({ currentModel, currentYear, UserActions, userMedia }) => {
  const isDesktop = useMediaQuery({ query: breakpoints.desktop })
  const isMobile = useMediaQuery({ query: breakpoints.mobile })
  const isMobileLandscape = useMediaQuery({ query: breakpoints.mobileLandscape })
  const isMobilePortrait = useMediaQuery({ query: breakpoints.mobilePortrait })

  useEffect(() => {
    if (isDesktop) UserActions.setMedia({ isDesktop: true, isLandscape: false })
    if (isMobile) UserActions.setMedia({ isDesktop: false, isLandscape: false })
    if (isMobileLandscape) UserActions.setMedia({ isDesktop: false, isLandscape: true })
    if (isMobilePortrait) UserActions.setMedia({ isDesktop: false, isLandscape: false })
  }, [isDesktop, isMobile, isMobileLandscape, isMobilePortrait])

  const isMobileInIframe = detectMobileForIframe() || document.querySelector('#root').clientWidth < UI.MIN_DESKTOP_WIDTH

  useEffect(() => {
    // analytics
    const host = window.location.host
    initGoogleAnalytics(getGaCode(host))
    setApplicationType(isMobileInIframe ? GA_APPLICATION_TYPE_MOBILE : GA_APPLICATION_TYPE_WEB)

    // Subscribe to navigation events
    const history = createBrowserHistory()
    history.listen(trackScreenView)
  }, [])

  // start ResizeObserver to send clientHeight to parent window
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }

    // for some reason content in mobile is cut off in iframe, so this is a workaround to monitor content height
    if (isMobileInIframe) {
      let interval = setInterval(() => {
        const HEADER_OFFSET = 60
        const DEFAULT_HEIGHT = 600
        const containerEl =
          document.querySelector('#root .screen-container') || document.querySelector('#root .Vehicles')
        const height = containerEl ? containerEl.clientHeight + HEADER_OFFSET : DEFAULT_HEIGHT

        if (height && height !== prevMobileHeight) {
          prevMobileHeight = height
          window.parent.postMessage({ height }, '*')
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let { target } of entries) {
        if (target.clientHeight) {
          window.parent.postMessage({ height: target.clientHeight }, '*')
        }
      }
    })

    resizeObserver.observe(document.querySelector('#root .container'))

    return () => {
      resizeObserver.disconnect()
    }
  }, [userMedia])

  const checkForModelFamily = (year, model) => {
    return year in VEHICLE_FAMILIES && typeof VEHICLE_FAMILIES[year] === 'object' && model in VEHICLE_FAMILIES[year]
  }

  // if group isn't there but we have a model then open corresponding group
  const currentGroup = useMemo(() => {
    if (!currentModel) return null

    const groups = getModelGroups([currentModel])
    return groups ? groups[0] : null
  }, [currentModel])

  // should wait for user media isDesktop/isLandscape set
  if (!userMedia) return null

  return (
    <Router>
      <Switch>
        {/* quick-fix for the breaking change introduced in 2.0 -> 2.1, see details in: OI-432 */}
        <Route
          path={'/guide/:year/:model/all-topics/topic/:topic'}
          render={({ match }) => (
            <Redirect
              to={'/guide/' + match.params.year + '/' + match.params.model + '/browse/topic/' + match.params.topic}
            />
          )}
        />

        <Redirect exact from={ROUTE.INDEX} to={ROUTE.GUIDE} />

        {/* redirect directly to the selected year/model (if available) */}
        {currentYear && currentModel && (
          <Redirect exact from={ROUTE.GUIDE} to={ROUTE.GUIDE + getModelUrl(currentYear, currentModel)} />
        )}
        {!currentModel && <Redirect exact from={ROUTE.GUIDE} to={ROUTE.VEHICLES} />}

        {/* redirect directly to the selected year/group if available */}
        {currentYear && currentGroup && (
          <Redirect
            exact
            from={ROUTE.VEHICLES}
            to={ROUTE.VEHICLES + '/' + currentYear + '/' + getPathAlias(currentGroup)}
          />
        )}

        <Route path={ROUTE.BRANDS} component={Brands} />
        <Route path={ROUTE.VEHICLES + '/:year?/:group?'} component={Vehicles} />

        {/* redirect to the model family if it's available for current model name */}
        <Route
          exact
          path={ROUTE.GUIDE + '/:year/:model'}
          render={(props) => {
            const {
              match: {
                params: { year, model },
              },
              match,
            } = props

            if (checkForModelFamily(year, model)) {
              return <Redirect exact from={`${match.path}`} to={`${ROUTE.VEHICLES}/${year}/${model}`} />
            } else {
              return <Redirect exact from={match.path} to={`${match.url}${ROUTE.EXPLORE}`} />
            }
          }}
        />
        <Route path={ROUTE.GUIDE + '/:year/:model'} component={Guide} />

        <Route
          render={() => (
            <ErrorMessage
              className='inverse'
              title={MESSAGE.ERROR_NOT_FOUND}
              retryAction={ROUTE.INDEX}
              retryTitle='Visit Home Page'
              message={MESSAGE.ERROR_NOT_FOUND_MESSAGE}
            />
          )}
        />
      </Switch>
    </Router>
  )
}

Main.propTypes = {
  currentModel: PropTypes.string,
  currentYear: PropTypes.string,
  UserActions: PropTypes.shape({
    setMedia: PropTypes.func.isRequired,
  }),
  userMedia: PropTypes.shape({
    isDesktop: PropTypes.bool,
    isLandscape: PropTypes.bool,
  }),
  location: PropTypes.string,
  match: PropTypes.object,
}

function mapStateToProps({ vehicle, user }) {
  const { currentYear, currentModel } = vehicle
  const { promoSkipped, media: userMedia } = user

  return { currentYear, currentModel, promoSkipped, userMedia }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
