import React, { PureComponent, createRef } from 'react'
import { Document } from 'react-pdf'
import { VariableSizeList } from 'react-window'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { identity, debounce } from 'lodash/fp'

import PageRenderer from './PageRenderer'

import 'jquery-highlight'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import './PdfPreview.scss'

const RANGE_CHUNK_SIZE = 262144 // 256KiB
const PADDING_TOP = 12
const PADDING_BOTTOM = 12

class PdfPreview extends PureComponent {
  static propTypes = {
    file: PropTypes.string,
    onDocumentLoadSuccess: PropTypes.func,
    options: PropTypes.object,
    scale: PropTypes.number,
    selectedPageIndex: PropTypes.number,
    viewerContainerHeight: PropTypes.number,
    highlightWords: PropTypes.arrayOf(PropTypes.string),
    loader: PropTypes.node,
    onInitPageSizeRetrieved: PropTypes.func,
  }

  static defaultProps = {
    onDocumentLoadSuccess: identity,
    options: {},
    scale: 1,
    selectedPageIndex: -1,
    highlightWords: [],
  }

  state = {
    pdf: null,
    cachedPageDimensions: null,
    scrollOffset: null,
    currIndex: -1,
    shouldScrollToHighlight: true,
  }

  constructor(props) {
    super(props)

    this.$pagesList = createRef()
    this.$document = createRef()

    this.scrollingToIndex = null

    this.refreshListHeightsDebounced = debounce(300, this.refreshListHeights)
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPageIndex, scale, viewerContainerHeight } = prevProps
    const { cachedPageDimensions } = prevState
    const { selectedPageIndex: newSelectedPageIndex, scale: newScale } = this.props
    const { cachedPageDimensions: newCachedPageDimensions } = this.state

    if (!cachedPageDimensions && newCachedPageDimensions) {
      this.handleScrollTo(newSelectedPageIndex)
    }

    if (newSelectedPageIndex !== selectedPageIndex) {
      this.setState(
        (prevState) => ({ ...prevState, shouldScrollToHighlight: true }),
        () => this.handleScrollTo(newSelectedPageIndex),
      )
    }

    if (this.props.viewerContainerHeight !== viewerContainerHeight || scale !== newScale) {
      this.refreshListHeights()
    }

    if (scale !== newScale && this.state.currIndex > -1 && this.$pagesList.current) {
      this.$pagesList.current.scrollToItem(this.state.currIndex)
    }
  }

  refreshListHeights = () => {
    if (this.$pagesList.current) {
      this.$pagesList.current.resetAfterIndex(0)
    }
  }

  onDocumentLoadSuccess = (pdf) => {
    this.setState({ pdf })
    this.cachePageDimensions(pdf)
    this.props.onDocumentLoadSuccess(pdf)
  }

  getPageDimensions = (pageNumber, pdf) => {
    return pdf.getPage(pageNumber).then((pdfPage) => {
      const width = pdfPage.view[2]
      const height = pdfPage.view[3]

      return { width, height }
    })
  }

  cachePageDimensions(pdf) {
    const pages = Array.from({ length: pdf.numPages }, (v, i) => i)

    // assuming all pages have the same height
    this.getPageDimensions(1, pdf).then(({ width, height }) => {
      const pageDimensions = new Map()

      this.props.onInitPageSizeRetrieved({
        width: width,
        height: height,
      })

      for (const page of pages) {
        pageDimensions.set(page, {
          width: width,
          height: height,
        })
      }

      this.setState({
        cachedPageDimensions: pageDimensions,
      })
    })
  }

  computeRowHeight = (index) => {
    const { scale } = this.props
    const { cachedPageDimensions } = this.state
    const cachedPage = cachedPageDimensions.get(index)
    return scale * cachedPage.height + PADDING_TOP + PADDING_BOTTOM
  }

  handlePageLoaded = (page) => {
    const { cachedPageDimensions } = this.state

    const viewport = page.getViewport({ scale: 1 }) // TODO check it
    const cachedPage = cachedPageDimensions.get(page.pageIndex)
    if (cachedPage.isLoaded) {
      if (page.pageIndex === this.scrollingToIndex) {
        // current scroll position should be fine as it's based on the verified pages dimensions
        // no need to trigger re-render with "resetAfterIndex"
        this.scrollingToIndex = null
      }

      return
    }

    cachedPageDimensions.set(page.pageIndex, {
      height: viewport.height,
      width: viewport.width,
      isLoaded: true,
    })

    this.setState(
      {
        cachedPageDimensions,
      },
      () => {
        // invalidating cached pages dimensions as they could have been affected by just loaded page
        // TODO check if dimensions have really changed
        this.$pagesList.current.resetAfterIndex(page.pageIndex)
      },
    )
  }

  handleScrollTo = (pageIndex) => {
    if (pageIndex >= 0 && this.$pagesList.current) {
      this.scrollingToIndex = pageIndex
      this.$pagesList.current.scrollToItem(pageIndex)
    }
  }

  tweakAnnotationLayer = () => {
    this.props.highlightWords.forEach((word) => $(this.$document.current).highlight(word, { element: 'mark' }))

    this.scrollToHighlight()
  }

  scrollToHighlight = () => {
    const { currIndex, shouldScrollToHighlight } = this.state

    const highlights = document.querySelectorAll(`[data-page-number="${currIndex + 1}"] .highlight`)
    if (shouldScrollToHighlight && highlights.length) {
      // the order in DOM may not always be representing the order which is displayed, so we need to sort element by their parent style.top property
      const closestToTop = Array.from(highlights).sort(
        (elA, elB) => +elA.closest('span').style.top.split('px')[0] - +elB.closest('span').style.top.split('px')[0],
      )
      closestToTop[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
      this.setState((prevState) => ({ ...prevState, shouldScrollToHighlight: false }))
    }
  }

  handlePageRenderSuccess = (pageIndex) => {
    const { cachedPageDimensions } = this.state
    const cachedPage = cachedPageDimensions.get(pageIndex)
    if (cachedPage.isLoaded && pageIndex === this.scrollingToIndex) {
      this.scrollingToIndex = null
      this.$pagesList.current.scrollToItem(pageIndex)
    }

    this.tweakAnnotationLayer()
  }

  handleTogglePagesPreviewOpen = () => this.setState({ isPagesPreviewOpen: !this.state.isPagesPreviewOpen })

  handleItemsRender = (ev) => {
    const index = (ev.visibleStartIndex + ev.visibleStopIndex) / 2
    this.setState({
      currIndex: Math.round(index <= 0.5 ? 0 : index),
    })
  }

  render() {
    const { file, loader, viewerContainerHeight, scale, options } = this.props
    const { pdf, cachedPageDimensions } = this.state

    return (
      <Document
        inputRef={this.$document} // dont provide () => this.$document, as highlights wont work
        className='PdfPreview'
        file={file}
        onLoadSuccess={this.onDocumentLoadSuccess}
        options={{
          disableAutoFetch: true,
          disableStream: true,
          rangeChunkSize: RANGE_CHUNK_SIZE,
          ...options,
        }}
        loading={loader}
        onItemClick={(page) => this.handleScrollTo(page.pageNumber - 1)}
        externalLinkTarget='_blank'
      >
        {cachedPageDimensions && viewerContainerHeight && (
          <VariableSizeList
            ref={this.$pagesList}
            itemSize={this.computeRowHeight}
            height={viewerContainerHeight}
            itemCount={pdf.numPages}
            onItemsRendered={this.handleItemsRender}
            itemData={{
              scale,
              loader,
              numPages: pdf.numPages,
              cachedPageDimensions,
              onPageLoadSuccess: this.handlePageLoaded,
              onPageRenderSuccess: this.handlePageRenderSuccess,
              onGetTextSuccess: this.tweakAnnotationLayer,
            }}
            overscanCount={1}
          >
            {PageRenderer}
          </VariableSizeList>
        )}
      </Document>
    )
  }
}

export default PdfPreview
