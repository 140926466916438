import React from 'react'

export function home() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 20'
      style={{ enableBackground: 'new 0 0 24 20' }}
      xmlSpace='preserve'
    >
      <g
        className='inactive stroke'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      >
        <path d='M1 1h22M2.5 1v18h19V1' />
        <path d='M17.6 19V8.2H6.3V19M11.4 4.5h1M6.3 13.6h11.3' />
      </g>
      <g className='active'>
        <defs>
          <path id='a' d='M12 0H0v20h24V0z' />
        </defs>
        <g fill='none' fillRule='evenodd'>
          <mask id='b' fill='#fff'>
            <use xlinkHref='#a' />
          </mask>
          <path
            className='fill'
            d='M12.4 5.5h-1a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2M23 0H1a1 1 0 1 0 0 2h.5v17a1 1 0 0 0 1 1h2.9V8.2a1 1 0 0 1 1-1h11.2a1 1 0 0 1 1 1V20h2.9a1 1 0 0 0 1-1V2h.5a1 1 0 1 0 0-2'
            mask='url(#b)'
          />
          <path className='fill' d='M7.4 12.6h9.2V9.201H7.4zM7.4 18h9.2v-3.4H7.4z' mask='url(#b)' />
        </g>
      </g>
    </svg>
  )
}
