import React from 'react'

export function arrowReturn() {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace='preserve'
    >
      <path
        className='fill'
        d='M448,192l-128,96v-64H128v128h248c4.4,0,8,3.6,8,8v48c0,4.4-3.6,8-8,8H72c-4.4,0-8-3.6-8-8V168c0-4.4,3.6-8,8-8h248V96
  L448,192z'
      />
    </svg>
  )
}
