import React from 'react'
import PropTypes from 'prop-types'
import { Motion, spring } from 'react-motion'
import classNames from 'classnames'

import { UI } from '../config'
import Hammer from 'react-hammerjs'

import Icon from '../components/Icon'
import Media from '../components/Media'

export default class ImageMap extends React.Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    activeX: PropTypes.number,
    activeY: PropTypes.number,
    isDesktop: PropTypes.bool,
  }

  static defaultProps = {
    isDesktop: false,
  }

  zoomContainer = null
  scrollContainer = null
  animation = UI.EXPLORE_ZOOM_ANIMATION
  startZoom = 1
  startX = 0
  startY = 0

  constructor(props) {
    super(props)

    this.state = {
      zoom: 1,
      motionStyle: {
        scale: 1,
        activeX: spring(props.activeX),
        activeY: spring(props.activeY),
      },
      currentX: props.activeX,
      currentY: props.activeY,
      started: false,
    }

    //this.handleScroll = this.handleScroll.bind(this)
    this.handleGlobalPinch = this.handleGlobalPinch.bind(this)
  }

  handlePan(e) {
    const { zoom } = this.state

    // User pans container
    this.setState({
      currentX: this.startX - e.deltaX * 1.2,
      currentY: this.startY - e.deltaY * 1.2,
      motionStyle: {
        scale: zoom,
        activeX: spring(this.startX - e.deltaX * 1.2),
        activeY: spring(this.startY - e.deltaY * 1.2),
      },
    })
  }

  handlePinch(e) {
    const scale = this.startZoom * e.scale

    this.setState({
      zoom: scale > 1 ? (scale < 3 ? scale : 3) : 1,
    })
  }

  handlePinchStart() {
    this.startZoom = this.state.zoom
  }

  handlePanStart() {
    this.startX = this.state.currentX
    this.startY = this.state.currentY
  }

  handleGlobalPinch(e) {
    //Prevent default zoom on iOS10+
    e.preventDefault()
  }

  getScrollOffset(zoom, activeX, activeY) {
    const { offsetWidth, offsetHeight } = this

    const hotSpotScrollX = zoom * ((offsetWidth * activeX) / UI.EXPLORE_IMAGE_WIDTH) - 0.5 * offsetWidth
    const hotSpotScrollY = zoom * ((offsetHeight * activeY) / UI.EXPLORE_IMAGE_HEIGHT) - 0.5 * offsetHeight

    const maxScrollX = offsetWidth * (zoom - 1)
    const maxScrollY = offsetHeight * (zoom - 1)

    return [
      hotSpotScrollX > maxScrollX ? maxScrollX : hotSpotScrollX < 0 ? 0 : hotSpotScrollX,
      hotSpotScrollY > maxScrollY ? maxScrollY : hotSpotScrollY < 0 ? 0 : hotSpotScrollY,
    ]
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.zoomContainer) return
      const { offsetHeight, offsetWidth } = this.zoomContainer

      this.offsetHeight = offsetHeight
      this.offsetWidth = offsetWidth
    }, 100)

    document.addEventListener('gesturestart', this.handleGlobalPinch)
  }

  componentDidUpdate(prevProps, prevState) {
    const { src, activeX, activeY } = this.props
    const { currentX, currentY, zoom } = this.state

    if (prevProps.src !== src) {
      this.setState({
        zoom: 1,
        motionStyle: {
          scale: 1,
          activeX: spring(currentX),
          activeY: spring(currentY),
        },
      })
    }

    if (
      zoom !== prevState.zoom ||
      src !== prevProps.src ||
      activeX !== prevProps.activeX ||
      activeY !== prevProps.activeY
    ) {
      // change current HotSpot
      if (activeX !== prevProps.activeX || activeY !== prevProps.activeY) {
        this.setState({
          currentX: activeX,
          currentY: activeY,
          motionStyle: {
            scale: spring(zoom, this.animation),
            activeX: spring(activeX, this.animation),
            activeY: spring(activeY, this.animation),
          },
        })

        // zoom in
      } else if (zoom !== prevState.zoom && zoom === 1) {
        this.setState({
          currentX: activeX,
          currentY: activeY,
          motionStyle: {
            scale: spring(zoom, this.animation),
            activeX: spring(currentX, this.animation),
            activeY: spring(currentY, this.animation),
          },
        })

        // zoom out
      } else {
        this.setState({
          motionStyle: {
            scale: spring(zoom, this.animation),
            activeX: currentX,
            activeY: currentY,
          },
        })
      }
    }
  }

  componentWillUnmount() {
    //if (this.scrollContainer) this.scrollContainer.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('gesturestart', this.handleGlobalPinch)
  }

  render() {
    const { zoom } = this.state
    const { src, isDesktop } = this.props

    return (
      <Hammer
        onPinch={this.handlePinch.bind(this)}
        onPinchStart={this.handlePinchStart.bind(this)}
        onPan={this.handlePan.bind(this)}
        onPanStart={this.handlePanStart.bind(this)}
        onTouchStart={() => {
          this.setState({ started: true })
        }}
        {...(!isDesktop
          ? {
              onDoubleTap: () => {
                this.setState({ zoom: this.state.zoom > 1 ? 1 : 2 })
              },
            }
          : {})}
        options={{
          recognizers: {
            pinch: { enable: true },
          },
        }}
      >
        <div className='image-map-area'>
          <Motion
            defaultStyle={{ scale: 1, activeX: 0, activeY: 0, scrollLeft: 0, scrollTop: 0 }}
            style={this.state.motionStyle}
          >
            {(style) => {
              const scrollOffset = this.getScrollOffset(style.scale, style.activeX, style.activeY)

              return (
                <div
                  className={classNames('image-map', zoom > 1 && 'zoom', zoom > 1 && `zoom-${Math.round(zoom)}x`)}
                  tabIndex='-1'
                  ref={(scrollable) => (this.scrollContainer = scrollable)}
                >
                  <div
                    className='image-map-inner'
                    style={{
                      transform: `scale(${style.scale}) translate(-${scrollOffset[0] / style.scale}px, -${
                        scrollOffset[1] / style.scale
                      }px)`,
                    }}
                    ref={(zoomable) => (this.zoomContainer = zoomable)}
                  >
                    <img src={src} alt='visual search image map' />
                    {this.props.children}
                  </div>
                  <Media type='mobile'>
                    <div className={classNames('image-map-overlay', this.state.started && 'hidden')}>
                      <div className='image-map-gesture'>
                        <Icon type='gesture-pinch' />
                        Pinch to zoom
                      </div>
                      <div className='image-map-gesture'>
                        <Icon type='gesture-pan' />
                        Drag with one finger
                      </div>
                    </div>
                  </Media>
                </div>
              )
            }}
          </Motion>
        </div>
      </Hammer>
    )
  }
}
