import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classNames from 'classnames'
import { generatePath } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pathOr, groupBy, toPairs, prop, isEmpty } from 'lodash/fp'

import * as SearchPagesActions from 'tw-oi-core/actions/SearchPagesActions'

import Loader from '../components/Loader'
import { ROUTE, BRAND_INFINITI } from '../config'
import { generatePublicationLink } from '../utils/publication'

import TopicListItem from './TopicListItem'
import InfinitiTopicListItem from './InfinitiTopicsListItem'

import '../styles/TopicsList.scss'

const TopicsListSearch = ({
  topics,
  searchQuery,
  searchPublications,
  baseRoute,
  onClick,
  children,
  emptyText,
  className,
  getSearchPages,
  searchPagesByPublication,
  contentIndex,
  currentBrand,
}) => {
  const pdfPublications = useMemo(() => {
    return searchPublications.filter((publication) => !!publication.get('bodyPdf')).toJS()
  }, [searchPublications])

  useEffect(() => {
    if (searchQuery) {
      pdfPublications.forEach((publication) => {
        getSearchPages({
          publicationId: publication.id,
          searchQuery,
        })
      })
    }
  }, [searchQuery, pdfPublications])

  const grouppedTopics = useMemo(() => {
    const topicsList = (topics && topics.toJS()) || []
    return groupBy(({ publication }) => publication.publicationId, topicsList)
  }, [topics])

  if (isEmpty(grouppedTopics) && !prop('length', pdfPublications)) {
    return (
      <Loader type='status' className='inverse'>
        {emptyText}
      </Loader>
    )
  }

  const BrandedListItem = currentBrand === BRAND_INFINITI ? InfinitiTopicListItem : TopicListItem

  return (
    <ul className={classNames('TopicsList', className)}>
      {toPairs(grouppedTopics).map(([publicationId, topics]) => {
        if (topics.length === 1) {
          const topic = topics[0]
          const isFolder = topic.type === 'folder'
          const resourceKey = topic.resourceKey

          return (
            <BrandedListItem
              key={topic.publication.publicationId}
              to={`${baseRoute}${generatePublicationLink({ isFolder, resourceKey })}`}
              {...{ topic, onClick }}
            />
          )
        }

        return (
          <BrandedListItem
            key={publicationId}
            to={`${baseRoute}${generatePath(ROUTE.PUBLICATION_GROUP, { publicationId })}`}
            onClick={onClick}
            topic={contentIndex[publicationId]}
            matches={topics}
          />
        )
      })}

      {pdfPublications.map((topic) => {
        const publicationState = searchPagesByPublication[topic.id]
        const matches = pathOr([], ['data', 'items'], publicationState)
        const isFetching = prop('isFetching', publicationState)
        const resourceKey = topic.resourceKey

        return (
          <BrandedListItem
            key={topic.id}
            to={`${baseRoute}${generatePublicationLink({ isPdfPublication: true, searchQuery, resourceKey, matches })}`}
            {...{ topic, onClick, matches, isFetching }}
          />
        )
      })}

      {children}
    </ul>
  )
}

TopicsListSearch.propTypes = {
  topics: PropTypes.oneOfType([ImmutablePropTypes.list, ImmutablePropTypes.map]),
  searchPublications: ImmutablePropTypes.list,
  searchQuery: PropTypes.string,
  emptyText: PropTypes.string,
  baseRoute: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  getSearchPages: PropTypes.func,
  searchPagesByPublication: PropTypes.object,
  contentIndex: PropTypes.object,
  currentBrand: PropTypes.string,
}

TopicsListSearch.defaultProps = {
  searchQuery: '',
  emptyText: 'No topics found',
  isDesktop: false,
  searchPagesByPublication: {},
}

const mapStateToProps = (state) => ({
  contentIndex: state.contents.contentIndex,
  topics: state.contents.searchTopics,
  searchPagesByPublication: state.contents.searchPagesByPublication,
  searchPublications: state.contents.searchPublications,
  currentBrand: state.vehicle.currentBrand,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(SearchPagesActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(TopicsListSearch)
