import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { filterTopicsByField, sortItemsByTitle } from 'tw-oi-core/utils/data'
import {
  trackSearchResultsTopicClick,
  trackSearchKeyword,
  trackEmptyResultsSearchKeyword,
  trackPublicationClick,
  trackRecentlyViewedTopicClick,
} from 'tw-oi-core/services/analytics'

import { ROUTE, MESSAGE, BRAND_NISSAN, UI, BRAND_INFINITI } from '../../config'

import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import ScreenContainer from '../../components/ScreenContainer'
import TopicsList from '../../components/TopicsList'
import Loader from '../../components/Loader'
import SearchInput from '../../components/SearchInput'
import ErrorMessage from '../../components/ErrorMessage'
import BodyClass from '../../components/BodyClass'
import TopicsListSearch from '../../components/TopicsListSearch'
import InfinitiTopicsList from '../../components/InfinitiTopicsList'

import '../../styles/Topics.scss'
import './Search.scss'

class Search extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    errorSearch: PropTypes.object,
    topics: ImmutablePropTypes.list,
    publications: ImmutablePropTypes.list,
    recent: ImmutablePropTypes.list,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    ContentsActions: PropTypes.shape({
      setSearchQuery: PropTypes.func.isRequired,
      getContents: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    searchTopics: ImmutablePropTypes.list,
    searchContentsUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLandscape: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      sortDescend: false,
    }
  }

  shouldComponentUpdate(nextProps) {
    // do not hide loader until new content will arrive
    return !(
      !nextProps.errorSearch &&
      !nextProps.fetching &&
      this.props.fetching &&
      nextProps.searchContentsUpdated === this.props.searchContentsUpdated
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      if (this.props.searchQuery) {
        this.getSearchContents(this.props.searchQuery)
      }
    }

    if (prevProps.searchContentsUpdated !== this.props.searchContentsUpdated) {
      this.trackAnalyticsEvents()
    }
  }

  getSearchContents = (searchQuery) => {
    const { DEFAULT_LOCALE } = getConfig()
    const { currentBrand, currentYear, currentModel, ContentsActions } = this.props

    ContentsActions.getContents(currentBrand, currentYear, currentModel, DEFAULT_LOCALE, searchQuery)
  }

  /**
   * Captures analytics search events
   */
  trackAnalyticsEvents() {
    const { searchQuery, searchTopics } = this.props

    if (!searchQuery) {
      return
    }

    // track empty search results and return early
    if (searchTopics && searchTopics.size === 0) {
      trackEmptyResultsSearchKeyword(searchQuery)
    }

    // track search keyword
    trackSearchKeyword(searchQuery)
  }

  /**
   * Triggers search analytics event when search results are clicked
   *
   * @param {Immutable.Map} topic
   */
  onTopicClick(topic) {
    switch (topic.type) {
      case 'topic':
        trackSearchResultsTopicClick(topic.title)
        break
      case 'publication':
        trackPublicationClick(topic.title)
        break
      default:
      // Do nothing in case of unsupported content type
    }
  }

  renderTopics = () => {
    const { topics, recent, searchQuery, errorSearch, fetching, baseRoute, currentBrand } = this.props

    if (!searchQuery) {
      const recentlyViewed = sortItemsByTitle(
        filterTopicsByField(topics, recent ? recent.toJS() : [], 'resourceKey', true),
      )

      const BrandedTopicList = currentBrand === BRAND_INFINITI ? InfinitiTopicsList : TopicsList

      return (
        <BrandedTopicList
          topics={recentlyViewed}
          onClick={(topic) => trackRecentlyViewedTopicClick(topic.get('title'))}
          emptyText={MESSAGE.EMPTY_RECENT}
          baseRoute={baseRoute + ROUTE.SEARCH}
        />
      )
    }

    if (errorSearch) {
      return (
        <ErrorMessage className='inverse inline' retryAction={() => this.getSearchContents(this.props.searchQuery)} />
      )
    }

    if (searchQuery) {
      if (fetching) {
        return <Loader className='inverse' />
      } else {
        return (
          <TopicsListSearch
            searchQuery={searchQuery}
            baseRoute={baseRoute + ROUTE.SEARCH}
            onClick={(topic) => this.onTopicClick(topic)}
          />
        )
      }
    }
  }

  render() {
    const { currentBrand, match, searchQuery, ContentsActions, errorSearch, fetching, isLandscape, baseRoute } =
      this.props

    return (
      <div className='Topics Search one'>
        <div className='screen-content'>
          <ScreenContainer currentUrl={match.url}>
            <div className='topics'>
              <BodyClass className='collapsed-search' condition={isLandscape} />

              <SearchInput
                baseRoute={baseRoute}
                searchQuery={searchQuery}
                onChange={debounce(ContentsActions.setSearchQuery, UI.SEARCH_BOX_DELAY)}
              />
              {(!searchQuery || (!fetching && !errorSearch)) && (
                <div className='topics-subtitle'>
                  {searchQuery ? 'Search Results' : 'Recently Viewed'}
                  {currentBrand === BRAND_NISSAN ? <hr /> : null}
                </div>
              )}

              {this.renderTopics()}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({
  contents,
  vehicle,
  user: {
    recent,
    media: { isLandscape },
  },
}) {
  const {
    topics,
    fetching,
    currentAsset,
    fetchingAsset,
    searchQuery,
    searchTopics,
    errorSearch,
    searchContentsUpdated,
    publications,
  } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  recent = recent.getIn([currentYear, currentModel])

  return {
    topics,
    recent,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
    searchQuery,
    searchTopics,
    searchContentsUpdated,
    errorSearch,
    isLandscape,
    publications,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
